/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { put } from '../../utils/api';
import { SwitchCheckbox } from '@d-lighted/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { updatePageType } from 'redux/reducers/pageTypeReducer';
import {apiUtils} from '../../utils/apiUtils';
import { pushToGA, events } from '../../utils/gtmUtils';


const SwitchWrapper = styled.div`
  box-sizing: border-box;
  padding: 10px 0;
  display: inline-block;
`;

const messages = defineMessages({
  changeSuccess: {
    id: 'notify.meetings.pageType',
    defaultMessage: 'Calendar type changed successfully.'
  }
});

function PageTypeSwitch(props) {
  const { useNotify, intl, alias, pageType: pType, setPressed, setPageType: setPType, disabled } = props;
  const { status } = useSelector(
    (state) => state.online
  );
  const notify = useNotify(intl);
  const [pageType, setPageType] = useState(pType || false);
  const [uid, setUID] = useState(status[alias]?.uid);
  const dispatch = useDispatch();

  useEffect(() => {
    setUID(status[alias]?.uid)
  }, [status]);

  useEffect(() => {
    setPageType(pType);
  },[pType])

  const handleSwitchPageType = async (e) => {
    if (uid == null) return;
    setPressed(true);

    e.persist();
    let response = await put(
      `/booking_calendars/${uid}/page_type`
    );
    if (response?.status <= 299) {
      let formattedResponse = apiUtils.formatAxiosResponse(response);
      dispatch(updatePageType(formattedResponse));
      notify.setNotify(messages.changeSuccess);
    } else {
      notify.setError(response.data.message);
    }

    setPageType(!pageType);
    setPType(!pageType);
    e.target.value = !pageType;
    pushToGA({...events.change_bookingPage_type(pageType? "personal" : "team")})
  };

  return (
    <SwitchWrapper>
      <SwitchCheckbox
        id="page_type"
        name="page_type"
        width={60}
        height={30}
        labels={["",""]} // TODO: Allow empty labels in the component
        colors={['#00bbb5', '#d0d5d5']}
        onChange={(e) => handleSwitchPageType(e)}
        value={pageType}
        checked={pageType}
        disabled={disabled}
      />
    </SwitchWrapper>
  );
}

PageTypeSwitch.defaultProps = {
  alias: '',
};

export default injectIntl(PageTypeSwitch);

import { createAction, createReducer } from "@reduxjs/toolkit";

const INITIAL_STATUS = {
  pageType: false
};

export const updatePageType = createAction('update_page_type');

const pageTypeReducer = createReducer(INITIAL_STATUS,
  builder => {
    builder.addCase(updatePageType, (state, action) => {
      state.pageType = action.payload.data.pageType;
    })
  }
);

export default pageTypeReducer;
